import React, { Fragment } from "react"
import { useTheme, styled, Headline, Grid, Animate } from "unikit"
import { ProjectItem } from "../components"

const Section = styled.View(({ head }) => ({
  backgroundColor: head ? "dark" : "surface",
  paddingVertical: 60,
  width: "100%",
  alignItems: "center",
  paddingTop: head ? 100 : 0,
}))

const Wrapper = styled.View(({ theme }) => ({
  margin: "0 auto",
  maxWidth: theme.wrapperWidth,
  width: "80%",
}))

export default ({ pageContext = {} }) => {
  const theme = useTheme()
  console.log({ pageContext })
  return (
    <Fragment>
      <Section head>
        <Wrapper>
          <Headline style={{ color: "#FFF", textAlign: "center" }} animate>
            Projects we are proud of
          </Headline>
        </Wrapper>
      </Section>
      <Section>
        <Animate style={{ width: "100%" }} delay={750}>
          <Wrapper>
            <Grid gap={35} my="2rem">
              {pageContext.projects
                ? pageContext.projects.map(
                    ({ name, description, logo, images, color }, index) => {
                      return (
                        <ProjectItem
                          image={{
                            uri:
                              images && images[0]
                                ? images[0].secure_url
                                : undefined,
                          }}
                          title={name}
                          text={description}
                          logo={logo ? logo.secure_url : undefined}
                          color={color}
                          delay={index < 4 ? index * 100 : 100}
                        />
                      )
                    }
                  )
                : null}
            </Grid>
          </Wrapper>
        </Animate>
      </Section>
    </Fragment>
  )
}
